<template>
  <div class="container">
    <z-loader />
  </div>
</template>

<script>
import wAxios from '@/plugins/w/axios'

const api = {
  clone: (hash) => wAxios.post_auth(`/v1/teacher/examtemplates/${hash}/clone`)   
}

export default {

  components: {
    'z-loader': () => import('@/plugins/app@components/loader/z-loader')
  },

  data() { 
    return {
      hash: this.$route.params.hash
    }
  },

  async mounted() { 
    if (!this.hash) {
      return
    }

    try {
      const { data: { data: clonedExamTemplate }} = await api.clone(this.hash)
      this.$root.$bvToast.toast(
        'Test bol úspešne pridaný medzi vaše testy!', 
        {
          title: 'Podarilo sa!',
          autoHideDelay: 3000,
          variant: 'a-success',
          toaster: 'b-toaster-top-center',
          solid: true
        }
      )
      this.$router.push({ name: 'Exam', params: { id: clonedExamTemplate.id }})
    } catch(err) {
      this.$wToast.error(err)
      this.$router.push({name: 'Tests'})
    }
  }
}
</script>
